@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.navbar {
    height: 10%;
    border-bottom: 0.5px solid rgb(231, 228, 228);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #555;
  
    .wrapper {
      width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      
      .search {
        display: flex;
        align-items: center;
        border: 0.5px solid lightgray;
        padding: 3px;
  
        input {
          border: none;
          outline: none;
          background: transparent;
  
          &::placeholder {
            font-size: 12px;
          }
        }
      }
      .logo {
        font-size: 20px;
        font-weight: bold;
        color: #6439ff;
      }
      
      // .logo {
      //   font-size: 20px;
      //   font-weight: bold;
      //   color: #ef476f;
      // }
      .items {
        display: flex;
        align-items: center;
  
        .item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          position: relative;

          .icon {
            font-size: 20px;
          }

          .avatar {
            width: 20rem;
            height: 3rem;
            @include md{
            width: 15rem;
            height: 2.6rem;
            }
          }
          .counter {
            width: 15px;
            height: 15px;
            background-color: red;
            border-radius: 50%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            font-weight: bold;
            position: absolute;
            top: -5px;
            right: -5px;
          }
         
        }
      }
    }
  }
  