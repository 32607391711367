@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.sidebar {
    width:12rem;
    border-right: 0.5px solid rgb(230, 227, 227);
    min-height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include md{width: 4rem;}

  
    .top {
      height: 5.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
    @include lg{padding-left: 2rem;}
     
  
      .logo {
        font-size: 1.2rem;
        font-weight: bold;
        color: #ef476f;
        letter-spacing: 0.1rem;
        @include md{font-size: 1rem;}
      }
    }
  
    hr {
      height: 0;
      border: 0.5px solid rgb(230, 227, 227);
    }
  
    .center {
      padding-left: 10px;
  
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
  
        .title {
          font-size: 1rem;
          font-weight: 400;
          color: 	#888;
          margin-top: 1rem;
          margin-bottom:0.5rem;
          text-transform: uppercase;
          @include md{
            display: none;
          }
        }
  
        li {
          display: flex;
          align-items: center;
          padding: 0.4rem;
          cursor: pointer;
  
          &:hover {
            background-color: #ece8ff;
          }
  
          .icon {
            font-size: 1.8rem;
            color: #ef476f;
           
          }
          .listItem{
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 20px;
          }
          .listItemTitle{
              font-size: 1rem;
              font-weight: 600;
              color: #595b60;
              margin-left: 10px;
              @include md{
                display: none;
              }
          }
        }
      }
    }
  
    .bottom{
        display: flex;
        align-items: center;
        margin: 10px;
  
        .colorOption{
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #ef476f;
            cursor: pointer;
            margin: 5px;
  
            &:nth-child(1){
                background-color: whitesmoke;
            }
            &:nth-child(2){
                background-color: #333;
            }
            &:nth-child(3){
                background-color: darkblue;
            }
        }
    }
  }
  