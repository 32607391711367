
@import "../../styles/responsive.scss";
.home {
    display: flex;
    
    .homeContainer {
      flex:5;
     
    /* Add height and align-items properties */
   /* Adjust the height as needed */
    /* Center items vertically */

      .widgets,
      .charts {
        display: flex;
        padding: 20px;
        gap: 20px;
      }
  
      .charts {
        padding: 5px 20px;
      }
  
      .listContainer {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        margin: 20px;
  
        .listTitle {
          font-weight: 500;
          color: gray;
          margin-bottom: 15px;
        }
      }
.welcome{
  display: flex;
  flex-direction: column;
   align-items: center;
   padding-top: 6rem;
      
    ;
  .welcomehead{
    text-align: center;
    font-size: 2rem;
    @include lg{
      font-size: 1.7rem;}
    
  }
  .admin{
    font-size: 1.5rem;
    @include md{
      font-size: 1rem;}
    @include lg{
      font-size: 1.4rem; }}
}

/*------------------------------------*/
.sectionClass {
  padding: 20px 0px 50px 0px;
  position: relative;
  display: block;
  /* Your styles */
}

.fullWidth {
  width: 100% !important;
  display: table;
  float: none;
  padding: 0;
  min-height: 1px;
  height: 100%;
  position: relative;
  /* Your styles */
}

.projectFactsWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* Your styles */
}

.itemy   {
  width: 25%; /* Adjust width based on the number of cards */
  height: 100%;
   padding: 50px 0px;
  text-align: center;
  line-height: 2rem;
  margin-top: 1%;
  // border: 1px solid grey;
  // border-radius: 10%; /* Initially transparent border */
  position: relative;
  
  border-radius:2rem;
transition: .5s;
//box-shadow: -10px 1px 6px 2px #ddd;
box-shadow: 0 0 10px 5px #ddd;
&:hover {
  box-shadow: 0px 3px 50px 4px #ddd ;}
  /* Your styles */
  @include lg {
    width: 15rem;
    margin: 0% 0% 5% 0%;
  
  }


   .number {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 1.3rem;    
    color:#939393;
  }
  .icon{
    font-weight: 900;
    font-size: 2rem;
    color:#ef476f;
  }
.title{
  font-weight: 400;
    font-size: 1.2rem;
    color:#009bdc;
}

}




/*------------------------------------*/
// .card-container {
//   display: flex;
//   justify-content: space-around;
  

//   // Apply responsive styles
//   @include sm {
//     flex-wrap: wrap;
//   }
// }
// .card {
//   width: calc(28% - 20px); /* Adjust width based on the number of cards */
//   height: calc(40% - 20px); /* Adjust width based on the number of cards */
//   padding: 70px;
//   background-color: #f0f0f0;
//   text-align: center;
//  margin-top: 4%;
  


 
  @include md {
    width: calc(50% - 20px); /* Adjust width for medium screens */
  }

  @include lg {
    width: calc(25% - 20px); /* Adjust width for larger screens */
  }

//   // Add more mixins for other breakpoints if needed

 

//   h3 {
//     margin-top: 0%;
//     font-size: 18px;
//     /* Add other heading styles */
//   }

//   p {
//     font-size: 24px;
//     font-weight: bold;
//     /* Add other paragraph styles */
//   }
// }


    }
  }
  