// ul {
//     list-style-type: none;
//   }
  
//   li {
//     display: inline-block;
//   }
  
//   input[type="checkbox"][id^="myCheckbox"] {
//     display: none;
//   }
  
//   label {
//     border: 1px solid #fff;
//     padding: 10px;
//     display: block;
//     position: relative;
//     margin: 10px;
//     cursor: pointer;
//   }
  
//   label:before {
//     background-color: white;
//     color: white;
//     content: " ";
//     display: block;
//     border-radius: 50%;
//     border: 1px solid grey;
//     position: absolute;
//     top: -5px;
//     left: -5px;
//     width: 25px;
//     height: 25px;
//     text-align: center;
//     line-height: 28px;
//     transition-duration: 0.4s;
//     transform: scale(0);
//   }
  
//   label img {
//     height: 100px;
//     width: 100px;
//     transition-duration: 0.2s;
//     transform-origin: 50% 50%;
//   }
  
//   :checked + label {
//     border-color: #ddd;
//   }
  
//   :checked + label:before {
//     content: "✓";
//     background-color: grey;
//     transform: scale(1);
//   }
  
//   :checked + label img {
//     transform: scale(0.9);
//     /* box-shadow: 0 0 5px #333; */
//     z-index: -1;
//   }

// .card-body{
//   padding: 0%;
//   position: relative;
//   width:25rem;
//   height:20rem;
//   display: flex;
// }
//  .ImageGallery{
//   object-fit: cover;
//   width:25rem;
//   height: 20rem;
//  }

.imageUpload{
  display: flex;
  justify-content: center;
}

.card-body {
  padding: 0%;
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
}

.ImageGallery {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.imageUpload {
  display: flex;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 8rem;
  background-color: #E6E6E6;
}

@media (min-width: 576px) {
  .card-body {
    /* Adjust as needed */
    width: 15rem;
    height: 15rem;
  }

  .ImageGallery {
    /* Adjust as needed */
    width: 15rem;
    height: 15rem;
  }
}

@media (min-width: 768px) {
  .card-body {
    /* Adjust as needed */
    width: 20rem;
    height: 20rem;
  }

  .ImageGallery {
    /* Adjust as needed */
    width: 20rem;
    height: 20rem;
  }
}


/* Add more media queries for larger screen sizes as necessary */
.title {
  display: flex;
  flex-direction: column;
   align-items: center;
 margin-bottom: 1rem;
      
    ;
  .titlehead{
    text-align: center;
    font-size: 2rem;
    
    // @include lg{
    //   font-size: 1.7rem;}
    
  }}

  .homebtn{
    margin-bottom: 1rem;
    
    .icon{
      background-color: #ef476f;
    }
  }
  .fixed-header {
    position: sticky;
    top: 0;
    z-index: 1000; /* Adjust the z-index as needed */
    background-color: #fff; /* Background color for the fixed header */
    /* Add any other styles for positioning, padding, etc. */
  }
  // .scrollable-content {
  //   padding-top: 1rem;/* Adjust this to ensure content doesn't get hidden behind the fixed header */;
  //   /* Other styles for the scrollable content */
  // }  

  // .container{
  //    padding-bottom: 15rem;
  // }