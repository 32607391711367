.login{
    height:100vh;
    display:flex;
    justify-content: center;
    align-items: center;}

.login-wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;}
.input{ 
    width:20rem;    
    padding: 10px }

    .lButton {
        border: none;
        padding: 10px 20px;
        background-color: #0071c2;
        color: white;
        font-weight: bold;
        cursor: pointer;
        border-radius: 5px;
      }

      .lButton:disabled{
        background-color: #0071c28c;
        cursor: not-allowed;
      }
      
     .logo{
        width:90%;
        height: 3rem;
        padding-bottom: 20px;
     }
     
     p{
        font-size: 0.8rem;
        color:grey;     }
        a{
            color:blue;
            text-decoration: underline;
        }